import DialogModal from '../dialog';
import FooterModal from '../footer';
import OutlinedButton from '@/components/buttons/outlined';
import { delay } from '@/utils/dates/delay';
import { flushSync } from 'react-dom';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilledButton from '@/components/buttons/filled';

interface Props {
  isStatic?: boolean;
  reverse?: boolean;
  confirm?: string;
  cancel?: string;
  autoClose?: boolean;
  child?: ReactNode;
}

function alert() {}
let displayAlert: Function = () => {};

alert.hide = () => {
  return displayAlert(false);
};

alert.confirm = (title: string = '', description: string = '', { isStatic, reverse, confirm, cancel, autoClose, child }: Props = {}) => {
  return displayAlert(true, 'confirm', title, description, isStatic, reverse, confirm, cancel, true, autoClose ? autoClose : false, child);
};

alert.info = (title: string = '', description: string = '', { isStatic, reverse, confirm, cancel, autoClose, child }: Props = {}) => {
  return displayAlert(true, 'info', title, description, isStatic, reverse, confirm, cancel, false, autoClose ? autoClose : true, child);
};

alert.warning = (title: string = '', description: string = '', { isStatic, reverse, confirm, cancel, autoClose, child }: Props = {}) => {
  return displayAlert(true, 'warning', title, description, isStatic, reverse, confirm, cancel, false, autoClose ? autoClose : true, child);
};

alert.error = (title: string = '', description: string = '', { isStatic, reverse, confirm, cancel, autoClose, child }: Props = {}) => {
  return displayAlert(true, 'error', title, description, isStatic, reverse, confirm, cancel, false, autoClose ? autoClose : true, child);
};

alert.success = (title: string = '', description: string = '', { isStatic, reverse, confirm, cancel, autoClose, child }: Props = {}) => {
  return displayAlert(true, 'success', title, description, isStatic, reverse, confirm, cancel, false, autoClose ? autoClose : true, child);
};

alert.delete = (title: string = '', description: string = '', { isStatic, reverse, confirm, cancel, autoClose, child }: Props = {}) => {
  return displayAlert(true, 'delete', title, description, isStatic, reverse ? reverse : true, confirm, cancel, false, autoClose ? autoClose : true, child);
};

const AlertDialog = () => {
  const { t } = useTranslation();
  const refConfirm = useRef<HTMLButtonElement | null>(null); // Ref for button Confirm
  const refCancel = useRef<HTMLButtonElement | null>(null); // Ref for button Cancel

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isStatic, setIsStatic] = useState(true);
  const [style, setStyle] = useState<'confirm' | 'success' | 'info' | 'warning' | 'error' | 'delete' | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [reverse, setReverse] = useState(false);
  const [textConfirm, setTextConfirm] = useState('');
  const [textCancel, setTextCancel] = useState('');
  const [isXClose, setIsXClose] = useState(true);
  const [child, setChild] = useState<ReactNode>(undefined);
  let refAlert = useRef(null);

  displayAlert = async (show: boolean, style: 'confirm' | 'success' | 'info' | 'warning' | 'error' | 'delete', title?: string, description?: string, isStatic?: boolean, reverse: boolean = false, confirm?: string, cancel?: string, xClose?: boolean, autoClose: boolean = true, child?: ReactNode) => {
    setStyle(style);
    setTitle(title);
    setDescription(description);
    setIsStatic(isStatic ?? true);
    setReverse(reverse);
    setTextConfirm(confirm ? confirm : t('confirm') ?? '');
    setTextCancel(cancel ? cancel : t('cancel') ?? '');
    setIsXClose(xClose ?? true);
    setChild(child);

    flushSync(() => {
      setShow(show);
      setLoading(false);
    });
    await delay(300);
    return new Promise((resolve, reject) => {
      // Confirm Button
      if (refConfirm.current) {
        refConfirm.current.addEventListener('click', (event) => {
          console.log('autoClose', autoClose);
          if (autoClose) setShow(false);
          else setLoading(true);
          resolve(true);
        });
      }
      // Cancel Button
      if (refCancel.current) {
        refCancel.current.addEventListener('click', (event) => {
          setShow(false);
          resolve(false);
        });
      }
    });
  };

  return (
    <DialogModal key={'alert'} ref={refAlert} isAlert={true}  show={show} style={style == 'confirm' ? undefined : style} isStatic={isStatic} onClose={() => (isStatic ? null : setShow(false))}>
      <div className="text-center">
        <div className="mb-4 space-y-4">
          {title && <div className="label-h3">{title}</div>}
          {description && <div className="label-h6">{description}</div>}
          {child}
        </div>
        <FooterModal reverse={reverse}>
          <FilledButton data-cy='alert_confirm' loading={loading} ref={refConfirm} type="submit" color={style == 'confirm' ? 'success' : undefined}>
            {textConfirm}
          </FilledButton>
          {isXClose && (
            <FilledButton data-cy='alert_cancel' loading={loading} ref={refCancel} type="button" color={style == 'confirm' ? 'error' : undefined}>
              {textCancel}
            </FilledButton>
          )}
        </FooterModal>
      </div>
    </DialogModal>
  );
};
export default AlertDialog;

export { alert };
