import qs from 'qs';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { AuthStatus } from '@/models/enums/auth';
import { isNotEmpty, mergeObjects } from '@/utils/utils';
import { UserModel, SaleOrgModel } from '@/models/classes/user';
import { OrganizeModel } from '@/models/classes/organize';

import Service from '@/services';
import { CompanyModel } from '@/models/classes/organize/company';
import { ChannelModel } from '@/models/classes/organize/channel';
import { SalesModel } from '@/models/classes/organize/sales';
import { Cookie } from '@/utils/cookies/cookie';
import { MenuCallPlanItem } from '@/models/classes/plan/visit';
// import { MenuCallPlan } from '@/models/classes/plan/visit';

const service = new Service.AuthService();

// Interface
interface AuthState {
  auth: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    status: AuthStatus;
    token?: string;
    user: UserModel | null;
    saleOrg: SaleOrgModel | null | undefined;
  };
  organize: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: OrganizeModel | null;
    selected: {
      company: CompanyModel;
      channel: ChannelModel;
      sale: SalesModel;
    };
    open: boolean;
    isShowed: boolean;
    updated: number;
  };
  goodHistory: {
    open: boolean;
  };
  callplanMenu: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: MenuCallPlanItem[];
  };
}

// Initialize State
const initialState: AuthState = {
  auth: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    status: AuthStatus.unknow,
    token: undefined,
    user: null,
    saleOrg: null,
  },
  organize: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: null,
    selected: {
      company: new CompanyModel(),
      channel: new ChannelModel(),
      sale: new SalesModel(),
    },
    open: false,
    isShowed: false,
    updated: 0,
  },
  goodHistory: {
    open: false,
  },
  callplanMenu: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'Authorization',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
    openOrganize: (state, action) => {
      state.organize.open = action.payload;
    },
    authInit: (state) => {
      const urlToken = new URLSearchParams(window.location.search)?.get('accessToken');
      const queryToken = qs.parse(window.location.hash.split('?')[1], {
        ignoreQueryPrefix: true,
      })?.accessToken;
      const localToken = localStorage.getItem('accessToken');

      let token: string | undefined = undefined;
      if (isNotEmpty(urlToken) && typeof urlToken == 'string') {
        token = urlToken;
      } else if (isNotEmpty(queryToken) && typeof queryToken == 'string') {
        token = queryToken;
      } else if (isNotEmpty(localToken) && typeof localToken == 'string') {
        token = localToken;
      } else {
        token = undefined;
      }

      if (isNotEmpty(token) && token != undefined) {
        localStorage.setItem('accessToken', token);
        state.auth.token = token;
      } else {
        localStorage.clear();
        state.auth.status = AuthStatus.unauthenticated;
      }
    },
    authLogout: (_) => {
      localStorage.clear();
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(fetchUser.pending, (state) => {
      state.auth.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.auth.network.code = ServiceStatus.succeeded;
      console.log('user payload', action.payload);
      const user = action.payload;
      if (state.auth.token != null && user != undefined) {
        state.auth.status = AuthStatus.authenticated;
        state.auth.user = user.user;
        state.auth.saleOrg = user.saleOrg;

        if (user.saleOrg && user?.saleOrg?.selectedCompanyName && user.saleOrg.selectedChannelName && user.saleOrg.selectedSaleOrgCriteriaId) {
          Cookie.setOrganize(user.saleOrg.selectedCompanyName, user.saleOrg.selectedChannelName, user.saleOrg.selectedSaleOrgCriteriaId);
        }
      } else {
        state.auth.status = AuthStatus.unknow;
        state.auth.user = null;
        state.auth.saleOrg = null;
      }
    });

    builder.addCase(fetchUser.rejected, (state, action) => {
      state.auth.network.code = ServiceStatus.failed;
      state.auth.network.error = (action.payload as string) || 'Failed to fetch data';

      state.auth.status = AuthStatus.unauthenticated;
    });

    // Fetch Organization
    builder.addCase(fetchOrganize.pending, (state) => {
      state.organize.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchOrganize.fulfilled, (state, action) => {
      state.organize.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        if (state.organize.data == null) {
          const data = action.payload;
          const cookie = Cookie.getOrganize();

          console.log('cookie', cookie);
          console.log('data', data);

          const selected = {
            company: cookie.company ? data.data.companies.find((e) => e.value == cookie.company) ?? data.data.companies[0] : data.data.companies[0],
            channel: cookie.channel ? data.data.channels.find((e) => e.value == cookie.channel) ?? data.data.channels[0] : data.data.channels[0],
            sale: cookie.sale ? data.data.sales.find((e) => e.value == cookie.sale) ?? data.data.sales[0] : data.data.sales[0],
          };

          if (data.data.companies.length > 0 && selected.company.value == data.data.companies[0].value && data.data.channels.length > 0 && selected.channel.value == data.data.channels[0].value && data.data.sales.length > 0 && selected.sale.value == data.data.sales[0].value) {
            Cookie.removeOrganize();
          }

          state.organize.selected = selected;
          // First Load and Update
          if (!state.organize.updated) {
            state.organize.updated = 1;
          }
        }
        state.organize.data = action.payload.data;
      }
    });
    builder.addCase(fetchOrganize.rejected, (state, action) => {
      state.organize.network.code = ServiceStatus.failed;
      state.organize.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Update Organization
    builder.addCase(updateOrganize.fulfilled, (state, action) => {
      if (action.payload && action.payload.isSuccess) {
        state.organize.updated += 1;

        const data = action.payload;
        Cookie.setOrganize(data.company.value, data.channel.value, data.sale.value);
        state.organize.selected = {
          company: data.company,
          channel: data.channel,
          sale: data.sale,
        };
      }
    });

    //MARK: call plan menu
    builder.addCase(onFetchMenuCallPlan.pending, (state) => {
      state.callplanMenu.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchMenuCallPlan.fulfilled, (state, action) => {
      state.callplanMenu.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.callplanMenu.data = action.payload;
      }
    });
    builder.addCase(onFetchMenuCallPlan.rejected, (state, action) => {
      state.callplanMenu.network.code = ServiceStatus.failed;
      state.callplanMenu.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const fetchUser = createAsyncThunk('auth/user', async (_, thunkAPI) => {
  try {
    const user = await service.getUser();
    return user;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const fetchOrganize = createAsyncThunk('organize/fetch', async (payload: { company?: string | null; channel?: string | null } | undefined, thunkAPI) => {
  console.log('organize/fetch');
  try {
    const company = payload?.company;
    const channel = payload?.channel;
    let orgazination = await service.fetchOrganize(company, channel);
    if (!company && orgazination && orgazination.data) {
      let cookie = Cookie.getOrganize();
      if (orgazination.selectedValue) {
        Cookie.setOrganize(orgazination.selectedValue.company, orgazination.selectedValue.channel, orgazination.selectedValue.saleOrg);
        cookie = Cookie.getOrganize();
      }
      const company = cookie.company ? orgazination.data.companies.find((e) => e.value == cookie.company) ?? orgazination.data.companies[0] : orgazination.data.companies[0];
      orgazination = await service.fetchOrganize(company.value);
      if (orgazination) {
        const channel = cookie.channel ? orgazination.data.channels.find((e) => e.value == cookie.channel) ?? orgazination.data.channels[0] : orgazination.data.channels[0];
        orgazination = await service.fetchOrganize(company.value, channel.value);
      }
    }

    return orgazination;
  } catch (error: any) {
    console.log('error', error);
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const updateOrganize = createAsyncThunk('organize/update', async (payload: { company: CompanyModel; channel: ChannelModel; sale: SalesModel }, thunkAPI) => {
  try {
    const isSuccess = await service.updateOrganize(payload.company.value, payload.channel.value, payload.sale.value);
    return {
      isSuccess,
      company: payload.company,
      channel: payload.channel,
      sale: payload.sale,
    };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});
export const onFetchMenuCallPlan = createAsyncThunk('menu/callplan', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchMenuCallPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});
// Actions
export const { reset, patch, authInit, authLogout, openOrganize } = slice.actions;

// Export
export default slice.reducer;
