import * as Sentry from '@sentry/browser';
import axios from 'axios';
import Chart from 'chart.js/auto';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { errInterceptor, reqErrorInterceptor, reqInterceptor, resInterceptor } from '@/utils/axios_util';
import { getParam } from './utils/uri_util';
import { OfflineIndex } from './services/offline/index';
import './assets/fonts/font.scss';
import './i18n';
import './utils/number';
import './utils/string';
import 'reflect-metadata';
import './assets/style/MarkerCluster.Default.css'

//@ts-ignore
// import('./style/index.js').then((module:any)=>{
//   // console.log(module)
//   module.scssImportElement()
// })

const App = lazy(() => import('./App'));
const RefreshSpinner = lazy(() => import('@/components/spinner/refresh'));

//set up axios interceptor
// @ts-ignore reqInterceptor type error but usable
axios.interceptors.request.use(reqInterceptor, reqErrorInterceptor);
axios.interceptors.response.use(resInterceptor, errInterceptor);

//ChartJS defult config
Chart.defaults.font.family = 'Sarabun';

/**
 * Set up Sentry analytic
 * dev: https://ftdsentrylogdev.thaibevapp.com/
 * uat: https://ftdsentryloguat.thaibevapp.com/
 * prod: https://ftdsentrylog.thaibevapp.com/
 */
try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
} catch (error) {
  console.warn('Fail to init sentry');
}

/**
 * Init react config
 */
window.React = React; //Attemp workaround for react not found in zip bundle build
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

//Checking app version and offline status
const v = getParam('version');
const off = getParam('offline');
let version = v ? v : localStorage.getItem('version');
let isOffline = off ? off : localStorage.getItem('isOffline');

//Set up offline varible
if (isOffline == 'true') {
  window.isOffline = true;
  let offline = new OfflineIndex();
  offline.loadOfflineSaleOrg();
} else {
  window.isOffline = false;
  window.isOffline = false;
}

/**
 * Init react main component based on current active version
 */
root.render(
  <Suspense fallback={<RefreshSpinner animation />}>
    <App />
  </Suspense>
);
// console.log('React not found should be fix');
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



